import React from "react";
import styled from "styled-components";
import PuffLoader from "react-spinners/PuffLoader";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function PageLoader() {
  return (
    <Container>
      <PuffLoader size={80} color="#43a278" />
    </Container>
  );
}
