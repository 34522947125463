import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube, FaTwitter } from "react-icons/fa";
import { AiFillWechat } from "react-icons/ai";
import moment from "moment";

import { Inner } from "src/components/Layout";

const Container = styled.div`
  width: 100%;
  height: 378px;
  max-width: 100%;
  background-color: #f7f7f7;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 48px 0;
`;

const UpperBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Logo = styled.img`
  width: 240px;
  margin-right: 64px;
`;

const BottomBox = styled.div`
  width: 100%;
  margin-left: 304px;
`;

const Shares = styled.div`
  a {
    color: #4c4c4c;
    text-align: left;
  }

  display: flex;
  width: 300px;
  justify-content: space-between;
`;

const CopyRight = styled.div`
  display: flex;
  padding-top: 12px;

  a {
    color: #4c4c4c;
    text-align: left;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 600;
  text-align: left;
  color: #191919;
  line-height: 19px;
`;

const Column = styled.div``;

const List = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
  margin: 8px 0 0;
  list-style: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin-bottom: 4px;
  line-height: 19px;

  a {
    color: #4c4c4c;
    font-size: 14px;
    text-align: left;
  }
`;

export function Footer() {
  const { t } = useTranslation();

  return (
    <Container>
      <Inner>
        <UpperBox>
          <Logo src="/assets/images/logo.png" alt="Logo" />

          <Content>
            <Column>
              <Title>{t("Locations")}</Title>

              <List>
                <ListItem>
                  <a href="#">{t("Austin")}</a>
                </ListItem>
                <ListItem>
                  <a href="#">{t("SF Bay Area")}</a>
                </ListItem>
              </List>
            </Column>

            <Column>
              <Title>{t("About FasGo!")} </Title>
              <List>
                <ListItem>
                  <a href="#">{t("Blog")}</a>
                </ListItem>

                <ListItem>
                  <a href="#">{t("Careers")}</a>
                </ListItem>

                <ListItem>
                  <a href="#">{t("Become a supplier")}</a>
                </ListItem>

                <ListItem>
                  <a href="#">{t("News & Media")}</a>
                </ListItem>
              </List>
            </Column>

            <Column>
              <Title>{t("Need Help?")}</Title>
              <List>
                <ListItem>
                  <a href="#">{t("Contact Us")}</a>
                </ListItem>

                <ListItem>
                  <a href="#">{t("Help Center")}</a>
                </ListItem>
              </List>
            </Column>

            <Column>
              <Title>{t("App")}</Title>
              <List>
                <ListItem>
                  <a href="#">{t("Wechat Mini Program")}</a>
                </ListItem>
              </List>
            </Column>
          </Content>
        </UpperBox>
      </Inner>

      <Inner>
        <BottomBox>
          <Shares>
            <a href="#">
              <FaFacebook size={18} />
            </a>
            <a href="#">
              <FaInstagram size={18} />
            </a>
            <a href="#">
              <AiFillWechat size={18} />
            </a>
            <a href="#">
              <FaLinkedin size={18} />
            </a>
            <a href="#">
              <FaYoutube size={18} />
            </a>
            <a href="#">
              <FaTwitter size={18} />
            </a>
          </Shares>

          <CopyRight>
            <div>© 2021 -{moment().format("YYYY")} FasGo!, Inc.</div>

            <div style={{ paddingLeft: 24 }}>
              <span>
                <a href="#">{t("Terms")}</a>
                <span> | </span>
                <a href="#">{t("Privacy")}</a>
                <span> | </span>
                <a href="#">{t("Accessibility Statement")}</a>
              </span>
            </div>
          </CopyRight>
        </BottomBox>
      </Inner>
    </Container>
  );
}
