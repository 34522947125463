import React, { Suspense } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";

import { HomePage } from "src/containers/home";

import "./App.less";

// eslint-disable-next-line
const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback="loading">
        <Switch>
          <Route path="/home">
            <HomePage />
          </Route>

          <Redirect from="/" to="/home" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
