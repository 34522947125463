export function listToTree(arr) {
  const res = arr.map((item) => ({
    ...item,
    children: [],
  }));

  const m = res.reduce((acc, cur) => {
    // eslint-disable-next-line
    acc[cur.id] = cur;
    return acc;
  }, {});

  res.forEach((item) => {
    const parentId = item.parent;
    if (!parentId) return;
    const p = m[parentId];
    if (!p) return;
    p.children.push(item);
    // eslint-disable-next-line
    item.isChild = true;
  });

  return res.filter((item) => !item.isChild);
}
