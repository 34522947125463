import { coreApi } from "src/services/core";
import { listToTree } from "src/lib";

import { useResourceTranslation } from "./i18n";

export const { useListCategoriesQuery } = coreApi;

export function useCategoriesTree() {
  const { tr } = useResourceTranslation();

  const { data = {}, isLoading: loading } = useListCategoriesQuery({
    query: {
      _sort: "createAt",
    },
  });

  const { body: categories = [] } = data;

  const tree = listToTree(categories.map((c) => tr(c)));

  return {
    tree,
    loading,
  };
}
