/**
 * 分类条
 */

import React from "react";
import styled from "styled-components";
import { Space, Menu, Dropdown } from "antd";

import { useCategoriesTree } from "src/features/categories";
import { Inner } from "src/components/Layout";

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px 0;
`;

export const CategoriesMenu = () => {
  const { tree } = useCategoriesTree();

  return (
    <Inner>
      <Box>
        {tree.map((item) => {
          const { name, image, id, children } = item;

          const subMenu = (
            <Menu>
              {children.map((subItem) => (
                <Menu.Item key={subItem.id}>{subItem.name}</Menu.Item>
              ))}
            </Menu>
          );

          return (
            <Dropdown overlay={subMenu} key={id}>
              <Space direction="vertical" align="center" style={{ width: 80, cursor: "pointer" }}>
                <img src={image} alt={name} style={{ width: 26, height: 26 }} />
                {name}
              </Space>
            </Dropdown>
          );
        })}
      </Box>
    </Inner>
  );
};
