/**
 * 顶部中间显示的search
 */

import React from "react";
import styled from "styled-components";
import { Typography, TreeSelect, Input, Badge, Avatar, Space, Button } from "antd";
import { useTranslation } from "react-i18next";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";

import { Inner } from "src/components/Layout";
import { useCategoriesTree } from "src/features/categories";

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
`;

const Logo = styled.img`
  width: 240px;
`;

export const Search = () => {
  const { tree } = useCategoriesTree();

  const { t } = useTranslation();

  const translateTree = (originalTree = []) => {
    for (const item of originalTree) {
      item.title = item.name;
      item.value = item.id;

      if (item.children) {
        translateTree(item.children);
      }
    }

    return originalTree;
  };

  const treeData = translateTree([
    {
      key: "root",
      id: "root",
      name: t("All Categories"),
      value: "root",
      children: tree,
    },
  ]);

  const CategoriesSelect = (
    <TreeSelect
      treeDefaultExpandedKeys={["root"]}
      treeData={treeData}
      style={{ width: 180 }}
      size="large"
      defaultValue="root"
    />
  );

  return (
    <Inner>
      <Box>
        <Logo src="/assets/images/logo.png" alt="Logo" />

        <Input.Search
          size="large"
          addonBefore={CategoriesSelect}
          style={{ width: 600, marginTop: 18 }}
          placeholder={t("Search Enter here...")}
          enterButton
        />

        <Space style={{ marginTop: 18 }} size="large">
          <Button ghost type="primary">
            <MdDateRange style={{ transform: "translateY(3px)" }} size={18} />
            {t("DELIVERY DATE")}
          </Button>

          <Typography.Link style={{ display: "flex", alignItems: "center" }}>
            <Badge count={4} offset={[-10, 0]}>
              <Avatar
                icon={<AiOutlineShoppingCart style={{ transform: "translateY(3px)" }} />}
                size={48}
                style={{ marginRight: 8, backgroundColor: "#43a278" }}
              />
            </Badge>
            {t("MY CART")}
          </Typography.Link>
        </Space>
      </Box>
    </Inner>
  );
};
