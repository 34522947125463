import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { coreApi } from "src/services/core";

export const store = configureStore({
  reducer: {
    [coreApi.reducerPath]: coreApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(coreApi.middleware),
});

// configure listeners using the provided defaults
setupListeners(store.dispatch);
