/**
 * constants
 */

export const IconStyle = {
  transform: "translateY(2px)",
  marginRight: 4,
};

export const SupportLangs = { "en-US": "English", "zh-CN": "简体中文" };
