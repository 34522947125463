/**
 *  产品相关api
 */

import { createApi } from "@reduxjs/toolkit/query/react";
import FasgoCore from "@fasgo/fasgo-sdk-js";

import { CORE_BASE } from "src/config";

const core = new FasgoCore({
  base: CORE_BASE,
  token:
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJmYXNnbyIsInN1YiI6InNlc3Npb24iLCJqdGkiOiI2MWU1NmVjYWJjZWU4NjM2NjU1YTM0YTQiLCJpZCI6IjYxZTU2ZWNhYmNlZTg2MzY2NTVhMzRhNCIsInVzZXIiOiI2MWU1MTZlNWQ3NWQxMTEyODIyM2ZhYTgiLCJyb2xlcyI6WyJBRE1JTiJdLCJpYXQiOjE2NDI0MjYwNTgsImV4cCI6NDc5NjAyNjA1OH0.m1PeqheKFlh5O2LSwIvj6Ou_K2CjE-38feItLk9w4ynbLHjvFdGanLkw4r2Xg6cKbeKjl9trU8z7yVXLq1Gk3toBCSk0r4qfqO4Gt0hltp4skmHPbz5vwTAh1hDa81P3j5yIjqQcbjaXlSHW0fL1hy77cyzpSOs2pTKDlV_nUYs",
});

const baseQuery =
  () =>
  async ({ api, arg }) => {
    if (api) {
      const { body, headers } = await api(arg);

      return {
        data: {
          body,
          total: headers["x-total-count"] && Number(headers["x-total-count"]),
        },
      };
    }

    return {
      data: [],
    };
  };

export const coreApi = createApi({
  reducerPath: "coreApi",
  baseQuery: baseQuery(),
  endpoints: (build) => {
    return {
      listRecommendProducts: build.query({
        /**
         * @param {import("@fasgo/fasgo-sdk-js").ListRecommendProductsRequest} arg
         * @returns
         */
        query: (arg = {}) => ({ api: core.products.listRecommendProducts, arg }),
      }),
      listCategories: build.query({
        /**
         * @param {import("@fasgo/fasgo-sdk-js").ListCategoriesRequest} arg
         * @returns
         */
        query: (arg = {}) => ({ api: core.categories.listCategories, arg }),
      }),
    };
  },
});
