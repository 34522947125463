/**
 * 商品banner
 */

import React from "react";
import { Button, Divider, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { AiOutlineRight } from "react-icons/ai";

import { IconStyle } from "src/config";
import { useListRecommendProductsQuery } from "src/features/product";
import { ProductsBanner } from "src/components/Products";
import { OverlayLoader } from "src/components/Loaders";

const { Text } = Typography;

export function CategoryProducts({ category = {} }) {
  const { t } = useTranslation();
  const { name, children = [], id } = category;

  const allIds = [id, ...children.map((c) => c.id)];

  const { data = {}, isLoading } = useListRecommendProductsQuery({
    query: {
      category: allIds,
    },
  });

  const { body: products = [] } = data;

  return (
    <OverlayLoader loading={isLoading}>
      <div style={{ width: "100%" }}>
        <Divider />
        <ProductsBanner
          products={products}
          title={
            <Text strong style={{ fontSize: 18 }}>
              {name}
            </Text>
          }
          extra={
            <Button type="link" size="large">
              {t("more")}
              <AiOutlineRight style={IconStyle} />
            </Button>
          }
        />
      </div>
    </OverlayLoader>
  );
}
