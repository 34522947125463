import React from "react";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";

import { Page } from "src/components/Layout";
import { useCategoriesTree } from "src/features/categories";
import { PageLoader } from "src/components/Loaders";

import { Header } from "./header";
import { Search } from "./search";
import { CategoriesMenu } from "./categories-menu";
import { ActivitiesBanner } from "./activities-banner";
import { CategoryProducts } from "./category-products";
import { Footer } from "./footer";

/**
 * 固定在顶部
 */
const FixedTop = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 250px;
  background: white;
  z-index: 100;
`;

/**
 * 最顶部信息区域
 */
const HeaderArea = styled.div`
  width: 100%;
  background: #dadbd9;
  display: flex;
  justify-content: center;
`;

/**
 * 搜索区域
 */
const SearchArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

/**
 * 商品分类区域
 */
const CategoriesArea = styled.div`
  width: 100%;
  background: #fbfbfb;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  box-sizing: border-box;
`;

const Content = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 250px;
  z-index: -1;
`;

const ActivitiesArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export function HomePage() {
  const { tree, loading: categoriesLoading } = useCategoriesTree();
  if (categoriesLoading) {
    return <PageLoader />;
  }

  return (
    <Page>
      <Scrollbars autoHide>
        <FixedTop>
          <HeaderArea>
            <Header />
          </HeaderArea>

          <SearchArea>
            <Search />
          </SearchArea>

          <CategoriesArea>
            <CategoriesMenu />
          </CategoriesArea>
        </FixedTop>

        <Content>
          <ActivitiesArea>
            <ActivitiesBanner />
          </ActivitiesArea>

          {tree.map((category) => (
            <CategoryProducts key={category.id} category={category} />
          ))}

          <Footer />
        </Content>
      </Scrollbars>
    </Page>
  );
}
