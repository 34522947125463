import { useTranslation } from "react-i18next";

export function useResourceTranslation() {
  const { i18n } = useTranslation();

  const tr = (item = {}) => {
    const { translations = [], name = "", character = "", description = "" } = item;

    const translation = translations.find((tran) => tran.lang === i18n.language) || {};

    const tName = translation.name || name;
    const tCharacter = translation.character || character;
    const tDescription = translation.description || description;

    return {
      ...item,
      name: tName,
      character: tCharacter,
      description: tDescription,
    };
  };

  return {
    tr,
    i18n,
  };
}
