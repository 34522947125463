/**
 * 顶部信息
 */

import React from "react";
import styled from "styled-components";
import { Space, Typography, Dropdown, Menu } from "antd";
import { BiChat } from "react-icons/bi";
import { FaUserCircle, FaSignInAlt } from "react-icons/fa";
import { AiOutlineDown } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import { Inner } from "src/components/Layout";
import { DEFAULT_PHONE, IconStyle, SupportLangs } from "src/config";

const { Link } = Typography;

const Box = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Header = () => {
  const { t, i18n } = useTranslation();

  const curLang = i18n.language;

  const LangsMenu = (
    <Menu
      onClick={({ key }) => {
        i18n.changeLanguage(key);
      }}
    >
      {Object.keys(SupportLangs).map((lang) => {
        const title = SupportLangs[lang];

        return <Menu.Item key={lang}>{title}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <Inner>
      <Box>
        <Space size="large">
          <Link strong>
            {t("header.Call")}: {DEFAULT_PHONE}
          </Link>

          <Link strong>
            <BiChat style={IconStyle} />
            {t("header.ChatNow")}
          </Link>
        </Space>

        <Space size="large">
          <Link>
            <FaUserCircle style={IconStyle} />
            {t("header.Login")}
          </Link>
          <Link>
            <FaSignInAlt style={IconStyle} />
            {t("header.Register")}
          </Link>

          <Dropdown overlay={LangsMenu}>
            <Link>
              {SupportLangs[curLang]}
              <AiOutlineDown style={IconStyle} />
            </Link>
          </Dropdown>
        </Space>
      </Box>
    </Inner>
  );
};
