import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";
import "moment/locale/zh-cn";
import moment from "moment";
import { Provider } from "react-redux";

import * as envs from "src/config/env";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { store } from "./store";

moment.locale("zh-cn");

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* eslint-disable */
if (envs.ECHO) {
  for (const [key, value] of Object.entries(envs)) {
    console.log(`REACT_APP_${key}=${value}`);
  }
}
/* eslint-disable */
