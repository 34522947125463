/**
 *
 * @param {string} name environments name
 * @param {string} init default value
 * @returns {string} value
 */
function env(name, init) {
  const key = `REACT_APP_${name.toUpperCase()}`;
  const buildtimeValue = process?.env?.[key];

  const runtimeValue = window?._runtime_?.[key]; // eslint-disable-line
  const value = runtimeValue || buildtimeValue || init;

  return value;
}

export const ECHO = env("ECHO", "true").toLocaleLowerCase() === "true";
export const VERSION = env("VERSION", "0.0");
export const BUILD = env("BUILD", "20210712-000000");
export const COPYRIGHT = env("COPYRIGHT", "FasGo");

export const DEFAULT_PHONE = env("DEFAULT_PHONE", "(666)611-0666");

/**
 * sdk
 */
export const CORE_BASE = env("CORE_BASE", "http://localhost:9527/v0");

// 是否开启i18n debug
export const I18N_DEBUG = env("I18N_DEBUG", "on");
