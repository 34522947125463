/**
 * 活动 banner
 */

import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper.less"; // core Swiper
import "swiper/modules/navigation/navigation.less"; // Navigation module
import "swiper/modules/pagination/pagination.less"; // Pagination module

const Box = styled.div`
  width: 100%;
  display: flex;
  background: #fbfbfb;
  padding: 0 24px;
`;

const CarouseLink = styled.a`
  display: block;
`;

const CarouseItem = styled.div`
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: visible;
  border-radius: 10px;
  background-position: 50%;
  background-image: url(${(props) => props.img});

  @media screen and (min-width: 1920px) {
    height: 416px;
    width: 616px;
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    height: 333px;
    width: 488px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    height: 282px;
    width: 411px;
  }

  @media screen and (max-width: 1279px) {
    height: 282px;
  }
  height: 282px;
`;

export const ActivitiesBanner = () => {
  return (
    <Box>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={3}
        slidesPerGroup={1}
        centeredSlides
        loop={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        style={{ width: "100%", height: "auto", margin: "12px 0" }}
      >
        {["1", "2", "3", "4", "5", "6"].map((key) => (
          <SwiperSlide key={key}>
            <CarouseLink>
              <CarouseItem img={`/assets/images/activities/${key}.jpg`} alt="activity1" />
            </CarouseLink>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
