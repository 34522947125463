import styled from "styled-components";

export const Inner = styled.div`
  @media screen and (min-width: 1920px) {
    width: 1780px;
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    width: 1392px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    width: 1232px;
  }

  @media screen and (max-width: 1279px) {
    width: 1016px;
  }
`;
