/**
 * 商品简单卡片, 用于展示
 */

import React, { useState } from "react";
import styled from "styled-components";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { Button, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { format as formatNumber } from "friendly-numbers";

import { useResourceTranslation } from "src/features/i18n";

const { Option } = Select;

const { Text } = Typography;

const Container = styled.div`
  width: 212px;
  height: 412px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  color: unset !important;
  text-align: left;
`;

const Image = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;

  .product-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    object-fit: cover;
    margin: 0;
  }

  .image-header {
    position: absolute;
    top: 8px;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 23px;
    z-index: 1;
    font-weight: 400;
    padding: 0 8px;
  }
  cursor: pointer;
`;

const Title = styled.div`
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 3px;
  font-size: 16px;
  line-height: 21px;
  color: #333;
  margin-top: 8px;
`;

const Price = styled.div`
  color: #cb181a;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.41px;
  margin-right: 8px;
`;

const Sales = styled.div`
  display: flex;
  margin-top: 5px;
  line-height: 19px;
  font-size: 14px;
`;

const BtnContainer = styled.div`
  z-index: 10;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const CountContainer = styled.div`
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

export function ProductBriefCard({ product = {} }) {
  const { tr } = useResourceTranslation();

  const { name, unit, price, thumbnail, monthlySale, standards = [] } = tr(product);

  const [fav, setFav] = useState(false);

  const { t } = useTranslation();

  const [cart, setCart] = useState({});
  const [curStandardId, setCurStandardId] = useState((first(standards) || {}).id);

  const handlePlusCart = () => {
    const standard = cart[curStandardId] || {};

    const { count = 0 } = standard;

    setCart({
      ...cart,
      [curStandardId]: {
        count: count + 1,
      },
    });
  };

  const handleMinusCart = () => {
    const standard = cart[curStandardId] || {};

    const { count = 0 } = standard;

    setCart({
      ...cart,
      [curStandardId]: {
        count: Math.max(count - 1, 0),
      },
    });
  };

  return (
    <Container>
      <div>
        <Image>
          {thumbnail && <img className="product-image" src={thumbnail} alt={name} />}

          <div className="image-header">
            <Button
              type="link"
              style={{ padding: 0, color: "#777" }}
              onClick={(e) => {
                e.stopPropagation();
                setFav(!fav);
              }}
            >
              {fav && <BsBookmarkFill size={24} style={{ color: "red" }} />}

              {!fav && <BsBookmark size={24} />}
            </Button>
          </div>
        </Image>
        <Title>{name}</Title>

        {product.price && (
          <Price>
            ${price}/{t(unit)}
          </Price>
        )}

        <Sales>
          {t("Monthly sold")} {formatNumber(monthlySale - (monthlySale % 100))}+
        </Sales>
      </div>

      <div>
        <BtnContainer>
          <Select
            size="small"
            style={{ width: 130 }}
            value={curStandardId}
            onChange={(val) => {
              setCurStandardId(val);
            }}
          >
            {standards.map((standard) => {
              const { quantity, description } = tr(standard);

              return (
                <Option key={standard.quantity} value={standard.id}>
                  {quantity} {t(standard.unit)} {description ? `(${description})` : ""}
                </Option>
              );
            })}
          </Select>

          <CountContainer>
            <Button type="primary" shape="circle" onClick={handleMinusCart}>
              <AiOutlineMinus style={{ transform: "translateY(2px)" }} size={18} />
            </Button>

            <Text strong style={{ fontSize: 16 }}>
              {cart[curStandardId]?.count || 0}
            </Text>

            <Button type="primary" shape="circle" onClick={handlePlusCart}>
              <AiOutlinePlus style={{ transform: "translateY(2px)" }} size={18} />
            </Button>
          </CountContainer>
        </BtnContainer>
      </div>
    </Container>
  );
}
