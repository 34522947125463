import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from "swiper";
import { range } from "lodash";
import "swiper/modules/grid/grid.less";

import { Inner } from "src/components/Layout";
import { useWindowDimensions } from "src/hooks";

import { ProductBriefCard } from "./ProductBriefCard";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListBox = styled.div`
  width: 100%;
  display: flex;

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev {
    padding: 24px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 1px 10px rgb(0 0 0 / 19%);
    &:after {
      font-size: 12px;
      font-weight: bold;
      color: black;
    }
  }

  .swiper-button-next {
    padding: 24px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 1px 10px rgb(0 0 0 / 19%);
    &:after {
      font-size: 12px;
      font-weight: bold;
      color: black;
    }
  }
`;

const EmptySlide = styled.div`
  width: 212px;
  height: 322px;
`;

const SlidesPerView = {
  1280: 4, // 宽度小于1280 4个
  1440: 5,
  1920: 7,
  [Number.MAX_SAFE_INTEGER]: 8,
};

export function ProductsBanner({
  title, // 标题
  extra, // 额外操作
  products = [], // 产品
}) {
  const { width } = useWindowDimensions();

  const perViewWidth = Object.keys(SlidesPerView).find((w) => width < w);

  const slidesPerView = SlidesPerView[perViewWidth];

  return (
    <Container>
      <Inner>
        <Title>
          <div>{title}</div>
          {extra}
        </Title>

        <ListBox>
          <Swiper
            spaceBetween={5}
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerView}
            modules={[Navigation]}
            navigation
            allowTouchMove={false}
          >
            {products.map((product) => (
              <SwiperSlide key={product.id}>
                <ProductBriefCard product={product} />
              </SwiperSlide>
            ))}
            {
              // 如果 product 数量不够一屏
              products.length < slidesPerView &&
                range(slidesPerView - products.length).map((index) => (
                  <SwiperSlide key={index}>
                    <EmptySlide />
                  </SwiperSlide>
                ))
            }
          </Swiper>
        </ListBox>
      </Inner>
    </Container>
  );
}
