import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import LoadingOverlay from "react-loading-overlay";

export function OverlayLoader({ children, loading }) {
  return (
    <LoadingOverlay
      active={loading}
      spinner={<PuffLoader color="#43a278" />}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.2)",
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
}
